import { Base64 } from 'js-base64'

export const parseToken = (token) => {
    const payload = token && token.split('.')[1]

    if (!payload) {
        return null
    }

    try {
        return JSON.parse(Base64.decode(payload))
    } catch (e) {
        console.error(e)
        return null
    }
}
