import React from 'react';

const DEF_LIST = [{
  id: 'aliyun',
  name: '阿里云'
}, {
  id: 'tencent',
  name: '腾讯云'
}, {
  id: 'huawei',
  name: '华为云'
}, {
  id: 'chuanglan',
  name: '创蓝253'
}, {
  id: 'juhe',
  name: '聚合数据'
}]

function PartnerList(props) {
  const { grayscale = false, list = DEF_LIST } = props
  const items = []

  let i = 0

  for (const item of list) {
    i++
    const img = item ? <img src={require(`../images/${item.id}.logo-l.png`)} alt={item.name} loading="lazy" /> : ''
    items.push(
      <div key={i} className={`partner-logo flex items-center justify-center py-2 col-span-2 md:col-auto ${grayscale && 'grayscale'}`}>
        {img}
      </div>
    )
  }

  return (
    <div className="max-w-sm md:max-w-4xl mx-auto grid gap-2 grid-cols-4 md:grid-cols-5">
      {items}
    </div>
  )
}

export default PartnerList;
