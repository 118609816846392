import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useLocation,
  Redirect
} from 'react-router-dom';
import Cookies from 'js-cookie';

import './css/style.scss';

import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';

import Home from './pages/Home';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import SignUpUniCloud from "./pages/signin/uni_cloud"; // 合一云登录
import ProginnAuth from './pages/ProginnAuth'; // 客栈授权登录
import ResetPassword from './pages/ResetPassword';
import PricingStandard from './pages/PricingStandard';
import PricingStandardIntl from './pages/PricingStandardIntl';
import PricingManaged from './pages/PricingManaged';
import Documentation from './pages/Documentation';
import About from './pages/About';
import Privacy from './pages/Privacy';
import { UVI_CACHE_KEY } from './constant';
import { getQueryParams } from './utils/URL';
import { getStorageItem, setStorageItem } from './utils/Storage';
import { request } from './utils/Request';

const RE_WITHOUT_MEIQIA_PAGE = /\/(sign|reset-password|proginn)/
const USI_CACHE_KEY = 'uni_usi'
const STS_TOKEN_CACHE_KEY = 'uni_sts_token'

const getTimezone = () => {
  return -new Date().getTimezoneOffset() / 60
}

const initEnv = async () => {
  let uvi = getStorageItem(UVI_CACHE_KEY)

  if (uvi) {
    return
  }

  const now = Date.now()
  let uni_sts_token = Cookies.get(STS_TOKEN_CACHE_KEY)

  if (!uni_sts_token) {
    const { data } = await request({
      method: 'GET',
      url: '/api/env/init',
    })
    uni_sts_token = data && data.uni_sts_token

    if (uni_sts_token) {
      Cookies.set(STS_TOKEN_CACHE_KEY, uni_sts_token, {
        expires: new Date(now + (2 * 3600 - 15) * 1000)
      })
    }
  }

  if (!uni_sts_token) {
    return
  }

  const nav = window.navigator
  const screen = window.screen

  try {
    const { data } = await request({
      // baseURL: 'http://localhost:8200',
      baseURL: 'https://uni.apistd.com',
      method: 'GET',
      url: '/sts',
      params: {
        action: 'ads.visitor.identifier.get',
        token: uni_sts_token,
        pl: 1,
        tz: getTimezone(),
        sw: screen.width,
        sh: screen.height,
        cd: screen.colorDepth || screen.pixelDepth,
        pr: window.devicePixelRatio || 1,
        lg: (nav.language || nav.userLanguage || '').toLowerCase().replace(/_/g, '-'),
        vd: nav.vendor
      }
    })

    uvi = data && data.data && data.data.id

    if (uvi) {
      setStorageItem(UVI_CACHE_KEY, uvi)
      Cookies.set(UVI_CACHE_KEY, uvi, {
        expires: new Date(now + (365 * 24 * 3600 * 1000))
      })
    }
  } catch (e) {
    // 暂不处理
    console.debug('/sts exception', e)
  }
}

function App() {
  const location = useLocation();

  useEffect(() => {
    const stored_usi = Cookies.get(USI_CACHE_KEY)

    if (!stored_usi) {
      const usi = getQueryParams(location.search, 'usi')

      if (usi) {
        Cookies.set(USI_CACHE_KEY, usi, {
          expires: new Date(Date.now() + (90 * 24 * 3600 * 1000))
        })
      }
    }

    initEnv().catch(e => {
      console.error(e)
    })
  }, []);

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');

    const classNames = document.body.className.split(' ')

    if (RE_WITHOUT_MEIQIA_PAGE.test(location.pathname)) {
      document.body.className = Array.from(new Set([...classNames, 'no-widget'])).join(' ')
    } else {
      const idx = classNames.indexOf('no-widget')
      if (idx > -1) {
        classNames.splice(idx, 1)
        document.body.className = classNames.join(' ')
      }
    }

    const source = getQueryParams(location.search, 'source') || getQueryParams(location.search, 's')

    if (source) {
      const storedSource = getStorageItem('source')
      if (!storedSource || /^sms\./.test(storedSource)) {
        setStorageItem('source', source)
      }
    }
  }, [location.pathname, location.search]); // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/signin">
          <SignIn />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route>
        <Route path="/external/uni_cloud">
          <SignUpUniCloud />
        </Route>
        <Route path="/proginn/auth">
          <ProginnAuth />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/pricing/standard">
          <PricingStandard />
        </Route>
        <Route path="/pricing/standard-intl">
          <PricingStandardIntl />
        </Route>
        <Route path="/c/:country_id" >
          <PricingStandardIntl />
        </Route>
        <Route path="/pricing/managed">
          <PricingManaged />
        </Route>
        <Route path="/pricing">
          <Redirect to="/pricing/standard" />
        </Route>
        <Route path="/docs">
          <Documentation />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/contact">
          <About />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
      </Switch>
    </>
  );
}

export default App;
