import React from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import App from './App';
import * as serviceWorker from './serviceWorker';

const alertOptions = {
  position: positions.TOP_CENTER,
  timeout: 3000,
  offset: '30px',
  transition: transitions.SCALE
}
const component = (
  <React.StrictMode>
    <Router>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <App />
      </AlertProvider>
    </Router>
  </React.StrictMode>
)
const rootElement = document.getElementById('root')

if (rootElement.hasChildNodes()) {
  hydrate(component, rootElement);
} else {
  render(component, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
