import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Transition } from '@tailwindui/react';
import { DOC_MENU } from '../pages/Documentation';

const DocSubMenu = []
let i = 0

for (const item of DOC_MENU) {
  const Links = []
  for (const child of item.children) {
    Links.push(
      <Link key={child.path} to={'/docs' + child.path} className="block px-4 py-1 mb-1 text-medium text-gray-600 font-semibold hover:text-gray-900 outline-none">{child.label || child.title}</Link>
    )
  }

  DocSubMenu.push(
    <div key={i} className="px-2 md:py-2 md:hidden">
      <div className="px-1 mt-5 mb-3 uppercase tracking-wide font-semibold text-sm text-gray-900">{item.title}</div>
      {Links}
    </div>
  )
  i++
}

function Header() {
  const location = useLocation()
  const [top, setTop] = useState(true);
  const [subnavOpen, setSubnavOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isDoc, setIsDoc] = useState(false);

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  useEffect(() => {
    setIsDoc(/\/docs/.test(location.pathname))
    setMenuOpen(false)
  }, [location.pathname]);

  return (
    <header className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${!top && 'bg-white blur shadow-lg'}`}>
      <div className="relative z-30 max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* menu trigger */}
          <div className="p-4 -ml-5 -mr-1 mt-1 flex flex-row items-center justify-between">
            <button className="md:hidden">
              <div className={`menu-btn ${menuOpen ? 'open' : ''}`} onClick={(e) => { setMenuOpen(!menuOpen) }}>
                <div className="icon">
                  <div className="bar b1"></div>
                  <div className="bar b2"></div>
                  <div className="bar b3"></div>
                </div>
              </div>
            </button>
          </div>

          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <a href="https://unisms.apistd.com/" className="block" aria-label="UniSMS">
              <img src={require('../images/unisms.logo.png')} width="135" height="40" alt="UniSMS 合一短信" />
            </a>
          </div>
        
          <div className="flex-shrink-0 mr-4">
            <a href="https://unisms.apistd.com/">首页</a>
          </div>
          

          <div className={`w-full text-gray-700 fixed md:static md:block md:shadow-none ${menuOpen ? 'menu-expanded shadow-lg' : 'hidden'}`}>
            <div className="relative z-40 flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
              <nav className="flex-col flex-grow pb-4 md:pb-0 md:flex md:justify-end md:flex-row">
                <div className={`relative md:block ${isDoc ? 'hidden' : ''}`} onMouseEnter={() => setSubnavOpen(true)} onMouseLeave={() => setSubnavOpen(false)}>
                  <button className="flex flex-row items-center w-full px-4 py-2 mt-2 text-medium text-gray-600 font-semibold text-left md:w-auto md:inline md:mt-0 md:ml-4 hover:text-gray-900">
                    <span>产品及定价</span>
                    <svg fill="currentColor" viewBox="0 0 20 20" className={`inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1 ${subnavOpen ? 'rotate-180' : 'rotate-0'}`}><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                  </button>
                  <Transition
                    show={subnavOpen || menuOpen}
                    enter="transition duration-150"
                    enterFrom="opacity-0 transform -translate-y-2"
                    enterTo="opacity-100 transform translate-y-0"
                    leave="transition duration-250"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="md:absolute right-0 w-full origin-top-right bg-white rounded-md md:shadow-lg md:w-40">
                    <div className="px-2 md:py-2">
                      <a href="https://unisms.apistd.com/pricing/standard" className="block px-4 py-2 mt-1 text-medium text-gray-600 font-semibold md:mt-0 hover:text-gray-900 outline-none">标准短信 (国内)</a>
                      <a href="https://unisms.apistd.com/pricing/standard-intl" className="block px-4 py-2 mt-1 text-medium text-gray-600 font-semibold md:mt-0 hover:text-gray-900 outline-none">标准短信 (国际)</a>
                      <a href="https://unisms.apistd.com/pricing/managed" className="block px-4 py-2 mt-1 text-medium text-gray-600 font-semibold md:mt-0 hover:text-gray-900 outline-none">托管短信</a>
                    </div>
                  </Transition>
                </div>
                <a href="https://unisms.apistd.com/docs" className={`${isDoc ? 'hidden' : ''} px-4 py-2 mt-2 text-medium text-gray-600 font-semibold bg-transparent md:mt-0 md:ml-4 hover:text-gray-900 block md:inline-block`}>文档</a>
                {isDoc ? DocSubMenu : ''}
                <div className="mt-4 pt-2 border-t border-gray-200 md:border-0 md:mt-0 md:pt-0">
                  {isDoc ? <Link to="/" className="px-4 py-2 text-medium text-gray-600 font-semibold bg-transparent md:mt-0 md:ml-4 hover:text-gray-900 block md:hidden">返回首页</Link> : ''}
                  {/* <Link to="/signin" className="px-4 py-2 text-medium text-gray-600 font-semibold bg-transparent md:mt-0 md:ml-4 hover:text-gray-900 block md:inline-block">登录</Link>  */}
                  <a href="https://unisms.apistd.com/signin" className="px-4 py-2 text-medium text-gray-600 font-semibold bg-transparent md:mt-0 md:ml-4 hover:text-gray-900 block md:inline-block">登录</a>
                </div>
              </nav>
            </div>
          </div>

          {/* Site navigation */}
          <nav className="flex flex-grow">
            <ul className="flex flex-grow md:flex-none justify-end flex-wrap items-center">
              {/* <li>
                <Link to="/pricing/standard" className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">定价</Link>
              </li> */}
              {/* <li>
                <Link to="/signin" className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">登录</Link>
              </li> */}
              <li>
                <a href="https://unisms.apistd.com/signup" className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-0 rounded-full nowrap -mr-2 md:mr-0 relative">
                  <span>免费试用</span>
                  <span className="trial-tag bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500 text-xs relative">送体验金</span>
                  {/* <svg className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                  </svg> */}
                </a>
              </li>
            </ul>

          </nav>

        </div>
      </div>
      <div className={`overlay z-10 w-screen h-screen top-0 right-0 bottom-0 left-0 fixed ${menuOpen ? 'md:' : ''}hidden`} onClick={(e) => { setMenuOpen(!menuOpen) }}></div>
    </header>
  );
}

export default Header;
