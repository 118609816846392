import React, { useEffect } from 'react'
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import bash from 'react-syntax-highlighter/dist/esm/languages/prism/bash';
import http from 'react-syntax-highlighter/dist/esm/languages/prism/http';
import json from 'react-syntax-highlighter/dist/esm/languages/prism/json';
import xml from 'react-syntax-highlighter/dist/esm/languages/prism/xml-doc';
import go from 'react-syntax-highlighter/dist/esm/languages/prism/go';
import java from 'react-syntax-highlighter/dist/esm/languages/prism/java';
import javascript from 'react-syntax-highlighter/dist/esm/languages/prism/javascript';
import python from 'react-syntax-highlighter/dist/esm/languages/prism/python';
import php from 'react-syntax-highlighter/dist/esm/languages/prism/php';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism'
import Footer from '../partials/Footer';
import Header from '../partials/Header';
// docs
import indexDoc from '../docs/index.md';
import tutorialsDoc from '../docs/tutorials.md';
import apiCommonDoc from '../docs/api-common.md';
import apiSendDoc from '../docs/api-send.md';
import apiConsoleDoc from '../docs/api-console.md'
import apiVoiceVerificationDoc from '../docs/api-voice-verification.md';
import apiErrorCodes from '../docs/api-error-codes.md';
import pluginIpRestrictionDoc from '../docs/plugin-ip-restriction.md';
import pluginSmsRateLimitingDoc from '../docs/plugin-sms-rate-limiting.md';
import pluginSmsDlrDoc from '../docs/plugin-sms-dlr.md';
import sdkJavaDoc from '../docs/sdk-java.md';
import sdkGoDoc from '../docs/sdk-go.md';
import sdkNodeDoc from '../docs/sdk-node.md';
import sdkPythonDoc from '../docs/sdk-python.md';
import sdkPhpDoc from '../docs/sdk-php.md';

SyntaxHighlighter.registerLanguage('bash', bash);
SyntaxHighlighter.registerLanguage('http', http);
SyntaxHighlighter.registerLanguage('json', json);
SyntaxHighlighter.registerLanguage('xml', xml);
SyntaxHighlighter.registerLanguage('go', go);
SyntaxHighlighter.registerLanguage('java', java);
SyntaxHighlighter.registerLanguage('javascript', javascript);
SyntaxHighlighter.registerLanguage('python', python);
SyntaxHighlighter.registerLanguage('php', php);

const components = {
  code({node, inline, className, children, ...props}) {
    const match = /language-(\w+)(\(\S+\))?/.exec(className || '')
    const lang = !inline && match && match[1]
    const marker = match && match[2] && match[2].substr(1, match[2].length - 2)
    return lang && lang !== 'pure' ? (
      <SyntaxHighlighter className="highlighter" wrapLongLines={marker === 'bka'} style={tomorrow} language={lang} PreTag="div" children={String(children).replace(/\n$/, '')} {...props} />
    ) : (
      <code className={className} children={children} {...props} />
    )
  }
}

export const DOC_MENU = [{
  title: '概览',
  children: [{
    title: 'UniSMS介绍_短信文档SDK',
    keywords: '短信文档,短信接口,短信接码',
    description: 'UniSMS产品文档，为用户提供UniSMS短信接口操作指南、短信API文档、短信SDK下载等资料，使您方便的使用UniSMS合一短信平台',
    label: '介绍',
    path: ''
  }, {
    title: '快速上手',
    keywords: '短信验证码发送,短信接口,短信接码',
    description: '快速了解上手UniSMS产品，提供UniSMS短信接口操作指南、短信API文档、短信SDK下载等简介',
    path: '/tutorials'
  }]
}, {
  title: 'API通用说明',
  children: [{
    title: 'API 通用说明',
    keywords: '短信API,短信接口,短信接码',
    description: 'UniSMS产品API通用说明，提供UniSMS短信接口、短信验证码发送通用操作指南',
    label: '通用',
    path: '/api/common'
  }, {
    title: '发送短信API',
    keywords: '短信API,短信发送,短信接码',
    description: 'UniSMS合一短信发送短信API文档，提供UniSMS短信接口、短信验证码发送通用操作指南',
    label: '发送短信',
    path: '/api/send'
  }, {
    title: '发送语音验证码API',
    keywords: '短信API,语音验证码,语音API',
    description: 'UniSMS合一短信发送语音验证码API文档，提供UniSMS发送语音验证码API接口操作指南',
    label: '语音验证码',
    path: '/api/voice-verification'
  }, {
    title: '错误码及描述',
    keywords: '短信错误码,短信接口方案',
    description: 'UniSMS合一短信错误码及描述文档，提供UniSMS短信API验证码发送错误码及描述信息',
    label: '错误码',
    path: '/api/error-codes'
  }]
}, {
  title: ' 扩展',
  children: [{
    title: '短信IP限制(黑白名单)',
    keywords: '短短信IP限制,短信黑名单,短信白名单',
    description: 'UniSMS合一短信IP限制(黑白名单)，提供UniSMS短信API短信IP限制(黑白名单)使用信息',
    label: 'IP 限制',
    path: '/extension/ip-restriction'
  }, {
    title: '短信发送频率限制',
    keywords: '短信限制,短信发送频率',
    description: 'UniSMS合一短信发送频率限制，提供UniSMS合一短信发送频率限制使用信息',
    label: '发送频率限制',
    path: '/extension/sms-rate-limiting'
  }, {
    title: '短信发送状态报告(DLR)',
    keywords: '短信发送状态报告,短信DLR',
    description: 'UniSMS合一短信发送状态报告(DLR)，提供UniSMS合一短信发送状态报告(DLR)使用信息',
    label: '状态报告',
    path: '/extension/sms-dlr'
  }]
}, {
  title: 'SDK 接入',
  children: [{
    title: 'Java短信SDK',
    keywords: 'Java短信接口,Java短信API,Java短信SDK',
    description: 'UniSMS合一短信Java短信SDK，提供UniSMS合一短信Java短信接口、Java短信API、Java短信SDK使用信息',
    label: 'Java',
    path: '/sdk/java'
  }, {
    title: 'Go短信SDK',
    keywords: 'Go短信接口,Go短信API,Go短信SDK',
    description: 'UniSMS合一短信Go短信SDK，提供UniSMS合一短信Go短信接口、Go短信API、Go短信SDK使用信息',
    label: 'Go',
    path: '/sdk/go'
  }, {
    title: 'Node.js短信SDK',
    keywords: 'Node.js短信接口,Node.js短信API,Node.js短信SDK',
    description: 'UniSMS合一短信Node.js短信SDK，提供UniSMS合一短信Node.js短信接口、Node.js短信API、Node.js短信SDK使用信息',
    label: 'Node.js',
    path: '/sdk/node'
  }, {
    title: 'Python短信SDK',
    keywords: 'Python短信接口,Python短信API,Python短信SDK',
    description: 'UniSMS合一短信Python短信SDK，提供UniSMS合一短信Python短信接口、Python短信API、Python短信SDK使用信息',
    label: 'Python',
    path: '/sdk/python'
  }, {
    title: 'PHP短信SDK',
    keywords: 'PHP短信接口,PHP短信API,PHP短信SDK',
    description: 'UniSMS合一短信PHP短信SDK，提供UniSMS合一短信PHP短信接口、PHP短信API、PHP短信SDK使用信息',
    label: 'php',
    path: '/sdk/php'
  }]
}]

const menuTitleMap = (() => {
  const map = {}
  for (const item of DOC_MENU) {
    for (const child of item.children) {
      map[child.path] = child
    }
  }
  return map
})()

const docSiteName = `文档-UniSMS合一短信文档`

function Documentation() {
  useEffect(() => {
    const path = window.location.pathname.replace(/^\/docs/, '')
    const child = menuTitleMap[path.replace(/\/$/, '')]
    document.title = child ? `${child.title}-${docSiteName}` : docSiteName

    if (child && child.keywords) {
      document.querySelector('meta[name="keywords"]').setAttribute('content', child.keywords)
    }
    if (child && child.description) {
      document.querySelector('meta[name="description"]').setAttribute('content', child.description)
    }

  })

  const NavMenu = []
  let i = 0

  for (const item of DOC_MENU) {
    const ListItems = []
    for (const child of item.children) {
      ListItems.push(
        <li key={child.path}>
          <Link className="px-3 py-2 transition-colors duration-200 relative block hover:text-gray-900 text-gray-500" to={'/docs' + child.path}>
            <span className="rounded-md absolute inset-0 bg-cyan-50 opacity-0"></span>
            <span className="relative">{child.label || child.title}</span>
          </Link>
        </li>
      )
    }

    NavMenu.push(
      <li key={i} className="mt-8">
        <h5 className="px-3 mb-3 lg:mb-3 uppercase tracking-wide font-semibold text-sm lg:text-xs text-gray-900">{item.title}</h5>
        <ul>{ListItems}</ul>
      </li>
    )
    i++
  }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-24">
          <div className="lg:flex">
            <div id="sidebar" className="fixed z-20 inset-0 flex-none h-full bg-black bg-opacity-25 w-full lg:bg-white lg:static lg:h-auto lg:overflow-y-visible lg:pt-0 lg:w-60 xl:w-72 lg:block hidden">
              <aside className="h-full overflow-y-auto scrolling-touch lg:h-auto lg:block lg:relative lg:sticky lg:bg-transparent overflow-hidden lg:top-18 bg-white mr-24 lg:mr-0">
                <div className="hidden lg:block h-12 pointer-events-none absolute inset-x-0 z-10 bg-gradient-to-b from-white"></div>
                <nav className="px-1 pt-6 overflow-y-auto font-medium text-base sm:px-2 xl:px-2 lg:text-sm pb-10 lg:pt-8 lg:pb-14 sticky?lg:h-(screen-18)">
                  <ul>
                    {NavMenu}
                  </ul>
                </nav>
              </aside>
            </div>

            <div id="content" className="min-w-0 w-full flex-auto lg:static lg:max-h-full lg:overflow-visible">
              <div className="w-full flex">
                <div className="min-w-0 flex-auto px-2 sm:px-6 xl:px-8 pt-0 pb-24 md:px-4 md:pt-8 lg:pb-16">
                  <Switch>
                    <Route exact path="/docs">
                      <ReactMarkdown children={indexDoc} components={components} remarkPlugins={[gfm]} className="markdown" />
                      {/* <Redirect to="/docs/api-common" /> */}
                    </Route>
                    <Route exact path="/docs/tutorials">
                      <ReactMarkdown children={tutorialsDoc} components={components} remarkPlugins={[gfm]} className="markdown" />
                    </Route>
                    {/* apis */}
                    <Route exact path="/docs/api/common">
                      <ReactMarkdown children={apiCommonDoc} components={components} remarkPlugins={[gfm]} className="markdown" />
                    </Route>
                    <Route exact path="/docs/api/send">
                      <ReactMarkdown children={apiSendDoc} components={components} remarkPlugins={[gfm]} className="markdown" />
                    </Route>
                    <Route exact path="/docs/api/console">
                      <ReactMarkdown children={apiConsoleDoc} components={components} remarkPlugins={[gfm]} className="markdown" />
                    </Route>
                    <Route exact path="/docs/api/voice-verification">
                      <ReactMarkdown children={apiVoiceVerificationDoc} components={components} remarkPlugins={[gfm]} className="markdown" />
                    </Route>
                    <Route exact path="/docs/api/error-codes">
                      <ReactMarkdown children={apiErrorCodes} components={components} remarkPlugins={[gfm]} className="markdown" />
                    </Route>
                    {/* extensions */}
                    <Route exact path="/docs/extension/ip-restriction">
                      <ReactMarkdown children={pluginIpRestrictionDoc} components={components} remarkPlugins={[gfm]} rehypePlugins={[rehypeRaw]} className="markdown" />
                    </Route>
                    <Route exact path="/docs/extension/sms-rate-limiting">
                      <ReactMarkdown children={pluginSmsRateLimitingDoc} components={components} remarkPlugins={[gfm]} rehypePlugins={[rehypeRaw]} className="markdown" />
                    </Route>
                    <Route exact path="/docs/extension/sms-dlr">
                      <ReactMarkdown children={pluginSmsDlrDoc} components={components} remarkPlugins={[gfm]} rehypePlugins={[rehypeRaw]} className="markdown" />
                    </Route>
                    {/* sdks */}
                    <Route exact path="/docs/sdk/java">
                      <ReactMarkdown children={sdkJavaDoc} components={components} remarkPlugins={[gfm]} className="markdown" />
                    </Route>
                    <Route exact path="/docs/sdk/go">
                      <ReactMarkdown children={sdkGoDoc} components={components} remarkPlugins={[gfm]} className="markdown" />
                    </Route>
                    <Route exact path="/docs/sdk/node">
                      <ReactMarkdown children={sdkNodeDoc} components={components} remarkPlugins={[gfm]} className="markdown" />
                    </Route>
                    <Route exact path="/docs/sdk/python">
                      <ReactMarkdown children={sdkPythonDoc} components={components} remarkPlugins={[gfm]} className="markdown" />
                    </Route>
                    <Route exact path="/docs/sdk/php">
                      <ReactMarkdown children={sdkPhpDoc} components={components} remarkPlugins={[gfm]} className="markdown" />
                    </Route>
                    {/* redirects */}
                    <Route exact path="/docs/api-common"><Redirect to="/docs/api/common" /></Route>
                    <Route exact path="/docs/api-send"><Redirect to="/docs/api/send" /></Route>
                    <Route exact path="/docs/api-voice-verification"><Redirect to="/docs/api/voice-verification" /></Route>
                    <Route exact path="/docs/sdk-java"><Redirect to="/docs/sdk/java" /></Route>
                    <Route exact path="/docs/sdk-go"><Redirect to="/docs/sdk/go" /></Route>
                    <Route exact path="/docs/sdk-node"><Redirect to="/docs/sdk/node" /></Route>
                    <Route exact path="/docs/sdk-python"><Redirect to="/docs/sdk/python" /></Route>
                    <Route exact path="/docs/sdk-php"><Redirect to="/docs/sdk/php" /></Route>
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default Documentation;
