import React, { useEffect } from 'react';
import { siteTitle } from '../constant';
import Footer from '../partials/Footer';
import Header from '../partials/Header';
import PartnerList from '../partials/PartnerList';

const PREPARING_LIST = [null, {
    id: 'jiguang',
    name: '极光短信'
  },
  {
    id: 'yunpian',
    name: '云片'
  }, {
    id: 'baidu',
    name: '百度智能云'
  },
  null
]

function PricingManaged() {
  useEffect(() => {
    document.title = `短信托管服务-${siteTitle}`

    document.querySelector('meta[name="keywords"]').setAttribute('content', "短信验证码,国际短信验证码,短信接码")
    document.querySelector('meta[name="description"]').setAttribute('content', "UniSMS合一短信托管服务，提供全球运营商网络接入，3s可达，99.99%到达率，通道自动切换保障稳定")
  }, [])

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/* <div className="o_ i_ lt aw">
          <h1 className="h1 sm aos-init aos-animate" data-aos="zoom-y-out"></h1>
          <p className="rz la aos-init aos-animate" data-aos="zoom-y-out" data-aos-delay="150">No matter how many team members you have - our pricing is simple, transparent and adapts to the size of your company.</p>
        </div> */}

        <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20 pt-48 illustration-section">
          <div className="relative">
            <h1 className="h1 mb-4 mx-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-teal-400">UniSMS 短信托管服务</h1>
            <p className = "text-gray-600 text-lg md:text-xl mx-4">一次接入即可灵活调度多个主流短信平台，轻松获取高可用短信服务能力。 <br /> 服务公测中， 免费不限量使用。 </p>
          </div>
        </div>

        {/* Supported */}
        <section className="relative max-w-6xl mx-auto px-4 sm:px-6 my-24">
          <div className="text-center my-12">
            <h2 className="font-bold text-3xl mb-2">已开通可托管平台</h2>
            <h4 className="text-gray-600">同一接口统一后台实时监控，自由配置启用短信平台及权重</h4>
          </div>

          <PartnerList/>
        </section>

        {/* Preparing */}
        <section className="relative max-w-6xl mx-auto px-4 sm:px-6 my-24">
          <div className="text-center my-12">
            <h2 className="font-bold text-3xl mb-2">即将开通</h2>
            <h4 className="text-gray-600">更多短信服务平台持续接入中，敬请期待</h4>
          </div>

          <PartnerList grayscale={true} list={PREPARING_LIST}/>
        </section>

      </main>

      <Footer />
    </div>
  );
}

export default PricingManaged;
