import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useHistory, useLocation } from 'react-router-dom';
import Footer from '../partials/Footer';
import Header from '../partials/Header';
import { request } from '../utils/Request';
import { siteTitle } from '../constant';
import { getStorageItem, setStorageItem } from '../utils/Storage';
import { Link } from 'react-router-dom';

const globalHeatList = [{
  id: 'ena',
  title: '欧美',
  list: [{
    "name": "United States",
    "en": "United States",
    "cn": "美国",
    "iso3": "USA",
    "iso2": "US",
    "country_code": "+1",
    "price": 0.0534
  }, {
    "name": "Canada",
    "en": "Canada",
    "cn": "加拿大",
    "iso3": "CAN",
    "iso2": "CA",
    "country_code": "+1",
    "price": 0.0824
  }, {
    "name": "United Kingdom",
    "en": "United Kingdom",
    "cn": "英国",
    "iso3": "GBR",
    "iso2": "GB",
    "country_code": "+44",
    "price": 0.336
  }, {
    "name": "France",
    "en": "France",
    "cn": "法国",
    "iso3": "FRA",
    "iso2": "FR",
    "country_code": "+33",
    "price": 0.5667
  }, {
    "name": "Deutschland",
    "en": "Germany",
    "cn": "德国",
    "iso3": "DEU",
    "iso2": "DE",
    "country_code": "+49",
    "price": 0.7048
  }, {
    "name": "Россия",
    "en": "Russia",
    "cn": "俄罗斯",
    "iso3": "RUS",
    "iso2": "RU",
    "country_code": "+7",
    "price": 0.5274
  }],
}, {
  id: 'esa',
  title: '东南亚',
  list: [{
    "name": "Singapore",
    "en": "Singapore",
    "cn": "新加坡",
    "iso3": "SGP",
    "iso2": "SG",
    "country_code": "+65",
    "price": 0.3167
  }, {
    "name": "Malaysia",
    "en": "Malaysia",
    "cn": "马来西亚",
    "iso3": "MYS",
    "iso2": "MY",
    "country_code": "+60",
    "price": 0.2667
  }, {
    "name": "Pilipinas",
    "en": "Philippines",
    "cn": "菲律宾",
    "iso3": "PHL",
    "iso2": "PH",
    "country_code": "+63",
    "price": 0.1418
  }, {
    "name": "Indonesia",
    "en": "Indonesia",
    "cn": "印度尼西亚",
    "iso3": "IDN",
    "iso2": "ID",
    "country_code": "+62",
    "price": 0.5
  }, {
    "name": "ประเทศไทย",
    "en": "Thailand",
    "cn": "泰国",
    "iso3": "THA",
    "iso2": "TH",
    "country_code": "+66",
    "price": 0.1067
  }, {
    "name": "Việt Nam",
    "en": "Vietnam",
    "cn": "越南",
    "iso3": "VNM",
    "iso2": "VN",
    "country_code": "+84",
    "price": 0.5
  }],
}, {
  id: 'other',
  title: '其他地区',
  list: [{
    "name": "日本",
    "en": "Japan",
    "cn": "日本",
    "iso3": "JPN",
    "iso2": "JP",
    "country_code": "+81",
    "price": 0.45
  }, {
    "name": "대한민국",
    "en": "South Korea",
    "cn": "韩国",
    "iso3": "KOR",
    "iso2": "KR",
    "country_code": "+82",
    "price": 0.1625
  }, {
    "name": "香港",
    "en": "Hong Kong S.A.R.",
    "cn": "中国香港特别行政区",
    "iso3": "HKG",
    "iso2": "HK",
    "country_code": "+852",
    "price": 0.3948
  }, {
    "name": "Australia",
    "en": "Australia",
    "cn": "澳大利亚",
    "iso3": "AUS",
    "iso2": "AU",
    "country_code": "+61",
    "price": 0.264
  }, {
    "name": "New Zealand",
    "en": "New Zealand",
    "cn": "新西兰",
    "iso3": "NZL",
    "iso2": "NZ",
    "country_code": "+64",
    "price": 0.6862
  }, {
    "name": "العربية السعودية",
    "en": "Saudi Arabia",
    "cn": "沙特阿拉伯",
    "iso3": "SAU",
    "iso2": "SA",
    "country_code": "+966",
    "price": 0.314
  }]
}]

function PricingStandard() {
  const location = useLocation()

  const getQueryCountry = () => {
    const country_id = location.pathname.substr(0, 3) === '/c/' ? location.pathname.substr(3).replace(/\//, '').toUpperCase() : ''

    let name = ''
    if (country_id !== '') {
      globalHeatList.forEach((groupItem) => {
        groupItem.list.forEach((item) => {
          if (item.iso2 === country_id) {
            name = item.cn
          }
        })
      })
    }

    return { country_id, name }
  }

  useEffect(() => {

    const country = getQueryCountry()
    if (country.country_id === '') {
      document.title = `标准国际短信服务定价-国际短信验证码_营销短信_通知提醒-${siteTitle}`
      document.querySelector('meta[name="keywords"]').setAttribute('content', "国际短信验证码,国际短信群发,国际短信")
      document.querySelector('meta[name="description"]').setAttribute('content', "UniSMS国际短信，全球直连通道，API接口发送，覆盖全球200个国家和地区，为企业拓展海外业务保驾护航")

      return;
    }

    document.title = `标准国际短信服务定价-${siteTitle}`
    // SEO
    document.title = `${country.name}短信验证码_${country.name}短信接口API通道-${siteTitle}`
    const meta_keywords = `${country.name}短信验证码、${country.name}短信接口、${country.name}短信API、${country.name}短信通道`
    const meta_description = `${siteTitle}国际短信平台为您提供${country.name}短信群发、${country.name}短信验证码、${country.name}短信接口、${country.name}短信API、${country.name}短信通道服务`

    document.querySelector('meta[name="keywords"]').setAttribute('content', meta_keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', meta_description)

    setIntlValue(intlValue)
    
    request({
      method: 'GET',
      url: '/api/lookup/intl/pricing',
      params: {
        code: country.country_id,
      }
    })
    .then(res => {
      setSearchIntlList(res.data.list)
    })
    .catch(e => {
      // 
    })
  }, [])

  const alert = useAlert()
  const history = useHistory()
  const [intlValue, setIntlValue] = useState('')
  const [searchIntlList, setSearchIntlList] = useState(null)

  const searchIntl = () => {
    if (!intlValue) {
      if (searchIntlList) {
        setSearchIntlList(null)
      } else {
        alert.error('请输入国家地区名称/代码或国际区号')
      }
      return
    }

    if (intlValue.length < 2 && !/^\d+$/.test(intlValue)) {
      alert.info('请两个以上字符进行搜索')
      return
    }

    request({
      method: 'GET',
      url: '/api/lookup/intl/pricing',
      params: {
        code: intlValue,
      }
    })
      .then(res => {
        setSearchIntlList(res.data.list)
      })
      .catch(e => {
        alert.error(e.message)
      })
  }

  const go = (source: string) => {
    const storedSource = getStorageItem('source')
    let mergedSource = `sms.${source}`

    if (storedSource && !/^sms\./.test(storedSource)) {
      mergedSource = `${storedSource},${mergedSource}`
    }

    setStorageItem('source', mergedSource)
    history.push(`/signup?source=${mergedSource}`)
  }

  const genPricingItems = (list: any[]) => {
    const items = []

    for (const item of list) {
      const featureEls = []

      for (const areas of item.list) {
        featureEls.push(
          <p className="my-2 flex items-center justify-between" key={'p_id_' + areas.iso2} >
            <img className="flag-icon" src={require(`../images/flags/${areas.iso2.toLowerCase()}.svg`)} />
            <span className="flex-1 ml-6 py-3 font-small text-gray-800 text-sm"><a href={'/c/'+areas.iso2.toLowerCase()} >{areas.cn}</a><br/><span className="text-gray-400 text-xs">{areas.iso2}, {areas.en}</span></span>
            <span className="py-3 font-small text-gray-700 text-sm">{areas.price ? `￥${areas.price}` : '-'}</span>
          </p>
        )
      }

      items.push(
        <div key={item.id} className="w-full md:w-1/3 bg-white rounded-lg shadow hover:shadow-xl transition duration-100 ease-in-out p-6 md:mr-6 mb-10 md:mb-0">
          <h3 className="font-bold text-xl">{item.title}</h3>
          <div className="text-sm text-gray-600 mt-4">
            {featureEls}
          </div>
        </div>
      )
    }

    return items
  }

  const SearchIntlTable = (props) => {
    const { list } = props

    if (!list) {
      return <p></p>
    } else if (!list.length) {
      return <p className="text-center text-gray-400">未查询到结果</p>
    }

    const rows = []

    for (const item of list) {
      rows.push(
        <tr key={item.iso3}>
          <td className="border-t border-gray-200 px-4 py-3 font-small text-gray-700 text-sm"><a href={'/c/'+item.iso2.toLowerCase()} >{item.cn}</a><br/><span className="text-gray-400 text-xs">{item.en}</span></td>
          <td className="border-t border-gray-200 px-4 py-3 font-small text-gray-700 text-sm">{item.iso2} / {item.iso3 }</td>
          <td className="border-t border-gray-200 px-4 py-3 font-small text-gray-700 text-sm">{item.country_code || '-'}</td>
          <td className="border-t border-gray-200 px-4 py-3 font-small text-gray-700 text-sm">{item.price ? `￥${item.price}/条起` : '-'}</td>
        </tr>
      )
    }

    return (
      <table className="table-fixed">
        <thead className="text-left">
          <tr>
            <th className="px-4 py-4">国家或地区</th>
            <th className="px-4 py-4">国际代码 (ISO-2/ISO-3)</th>
            <th className="px-4 py-4">国际电话区号</th>
            <th className="px-4 py-4">单价</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    )
  }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/* <div className="o_ i_ lt aw">
          <h1 className="h1 sm aos-init aos-animate" data-aos="zoom-y-out"></h1>
          <p className="rz la aos-init aos-animate" data-aos="zoom-y-out" data-aos-delay="150">No matter how many team members you have - our pricing is simple, transparent and adapts to the size of your company.</p>
        </div> */}

        <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20 pt-48 illustration-section">
          <div className="relative">
            <h1 className="h1 mb-4 mx-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-teal-400">UniSMS 国际标准短信</h1>
            <p className="text-gray-600 text-lg md:text-xl mx-4">精准调度国际电信运营商直连通道，与全球用户自由联通。<br/>现已覆盖全球 226+ 个国家及地区。</p>
          </div>
        </div>

        {/* 单价查询 */}
        <section className="relative max-w-6xl mx-auto px-4 sm:px-6 mb-32">
          <div className="text-center my-10">
            <div className="split-icon">
              <img src={require('../images/features-split-icon.svg')} alt="intl icon" />
            </div>
            <h2 className="font-bold text-3xl mb-2">国际短信单价查询</h2>
            <h4 className="text-gray-600">覆盖全球 226+ 个国家及地区，通过下方搜索框查询单价</h4>
          </div>

          <div className="l-search-box flex flex-col mx-auto">
            <div className="flex flex-row text-center mb-12">
              <input onKeyDown={(e) => e.key === 'Enter' && searchIntl()} type="text" value={intlValue} onChange={e => setIntlValue(e.target.value)} className="w-full appearance-none border border-gray-300 focus:border-blue-600 rounded-full px-4 md:px-8 py-3 placeholder-gray-500 md:mx-4 mx-2" placeholder="搜索国家地区名称/国际代码/国际区号以查询单价" aria-label="搜索国家地区名称/代码/国际区号以查询单价" />
              <button onClick={searchIntl} className="btn text-white bg-blue-600 hover:bg-blue-700 w-full sm:w-auto rounded-full nowrap">
                <img src={require('../images/search.svg')} alt="搜索" />
              </button>
            </div>
            <SearchIntlTable list={searchIntlList}/>
          </div>

        </section>

        {/* 热门地区 */}
        <section className="relative max-w-6xl mx-auto px-4 sm:px-6 my-24">
          <div className="text-center my-12">
            <h2 className="font-bold text-3xl mb-2">热门国家及地区</h2>
            <h4 className="text-gray-600">国际短信依据接收号码国家和地区计费，常见地区价格如下</h4>
          </div>

          <div className="flex flex-col md:flex-row px-2 md:px-0">
            {genPricingItems(globalHeatList)}
          </div>
        </section>

      </main>

      <Footer />
    </div>
  );
}

export default PricingStandard;
