import React from 'react';
import PartnerList from './PartnerList';

function ManagedChannels() {
  return (
    <section className="relative">

      {/* Illustration behind content */}
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -mb-32" aria-hidden="true">
        <svg width="1760" height="518" viewBox="0 0 1760 518" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-02">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g transform="translate(0 -3)" fill="url(#illustration-02)" fillRule="evenodd">
            <circle cx="1600" cy="256" r="256" />
            <circle cx="140" cy="481" r="32" />
          </g>
        </svg>
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h2 className="h2 mb-4"><span className="text-blue-600">多平台</span> 免费托管支持</h2>
            <p className="text-gray-600 text-lg md:text-xl" data-aos="zoom-y-out">为降低短信服务的接入、开发、使用、切换及管理成本，UniSMS 对以下常用云通信平台免费提供一键托管服务。仅须简单配置即可快速接入，与 UniSMS 集成标准服务商无缝衔接，享受性能卓越、稳定可靠的统一调度、管理。</p>
          </div>

          {/* Items */}
          <PartnerList grayscale={true} />

        </div>
      </div>
    </section>
  );
}

export default ManagedChannels;
