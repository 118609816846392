import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { Link } from 'react-router-dom';
import Footer from '../partials/Footer';
import Header from '../partials/Header';
// docs
import aboutDoc from '../docs/about.md';
import contactDoc from '../docs/contact.md';
import { siteTitle } from '../constant';

export const DOC_MENU = [{
  title: '关于',
  children: [{
    title: '公司简介',
    path: '/about'
  }, {
    title: '联系我们',
    path: '/contact'
  }]
}]

const menuTitleMap = (() => {
  const map = {}
  for (const item of DOC_MENU) {
    for (const child of item.children) {
      map[child.path] = child.title
    }
  }
  return map
})()


function About() {
  useEffect(() => {
    const path = window.location.pathname.replace(/^\/docs/, '')
    const title = menuTitleMap[path.replace(/\/$/, '')]
    document.title = title ? `${title} - ${siteTitle}` : siteTitle
  })

  const NavMenu = []
  let i = 0

  for (const item of DOC_MENU) {
    const ListItems = []
    for (const child of item.children) {
      ListItems.push(
        <li key={child.path}>
          <Link className="px-3 py-2 transition-colors duration-200 relative block hover:text-gray-900 text-gray-500" to={child.path}>
            <span className="rounded-md absolute inset-0 bg-cyan-50 opacity-0"></span>
            <span className="relative">{child.label || child.title}</span>
          </Link>
        </li>
      )
    }

    NavMenu.push(
      <li key={i} className="mt-8">
        <h5 className="px-3 mb-3 lg:mb-3 uppercase tracking-wide font-semibold text-sm lg:text-xs text-gray-900">{item.title}</h5>
        <ul>{ListItems}</ul>
      </li>
    )
    i++
  }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-24">
          <div className="lg:flex">
            <div id="sidebar" className="fixed z-20 inset-0 flex-none h-full bg-black bg-opacity-25 w-full lg:bg-white lg:static lg:h-auto lg:overflow-y-visible lg:pt-0 lg:w-60 xl:w-72 lg:block hidden">
              <aside className="h-full overflow-y-auto scrolling-touch lg:h-auto lg:block lg:relative lg:sticky lg:bg-transparent overflow-hidden lg:top-18 bg-white mr-24 lg:mr-0">
                <div className="hidden lg:block h-12 pointer-events-none absolute inset-x-0 z-10 bg-gradient-to-b from-white"></div>
                <nav className="px-1 pt-6 overflow-y-auto font-medium text-base sm:px-2 xl:px-2 lg:text-sm pb-10 lg:pt-8 lg:pb-14 sticky?lg:h-(screen-18)">
                  <ul>
                    {NavMenu}
                  </ul>
                </nav>
              </aside>
            </div>

            <div id="content" className="min-w-0 w-full flex-auto lg:static lg:max-h-full lg:overflow-visible">
              <div className="w-full flex">
                <div className="min-w-0 flex-auto px-2 sm:px-6 xl:px-8 pt-0 pb-24 md:px-4 md:pt-8 lg:pb-16">
                  <Switch>
                    <Route exact path="/about">
                      <ReactMarkdown children={aboutDoc} remarkPlugins={[gfm]} className="markdown" />
                    </Route>
                    <Route exact path="/contact">
                      <ReactMarkdown children={contactDoc} remarkPlugins={[gfm]} rehypePlugins={[rehypeRaw]} className="markdown" />
                    </Route>
                  </Switch>
                </div>
              </div>
              
              {/*
              <div className="w-full flex">
                <Link to="/docs" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">介绍</Link>
                <Link to="/signin" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">登录</Link>
                <Link to="/c/us" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">美国短信 </Link>
                <Link to="/pricing/standard" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">标准短信 (国内)</Link>
                <Link to="/pricing/standard-intl" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">标准短信 (国际)</Link>
                <Link to="/pricing/managed" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">托管短信</Link>
                <Link to="/contact" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">联系我们</Link>
              </div>
              */}

            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default About;
