import Axios from 'axios';

const instance = Axios.create({
  validateStatus: () => true
})

const validateStatus = (status) => {
  return status >= 200 && status < 300
}

export class RequestError extends Error {
  // constructor(msg: string) {
  //   super(msg)
  // }
}

export const request = async (input) => {
  let res

  try {
    res = await instance(input)
  } catch (e) {
    throw new RequestError(e)
  }

  const status = res.request && res.request.status

  if (res.data?.error) {
    const message = res.data?.error.message
    throw new RequestError(message)
  } else if (status && !validateStatus(status)) {
    throw new RequestError((status ? `[${status}] ` : '') + (res.statusText || 'Request failed'))
  }

  return res
}
