import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import { siteTitle } from '../constant';

import Header from '../partials/Header';
import { request } from '../utils/Request';
import { getStorageItem, setStorageItem } from '../utils/Storage'
import { parseToken } from '../utils/JWT'
import { createCaptchaImg } from '../utils/URL';
import { UVI_CACHE_KEY } from '../constant';
import css from "./signin/style.css";

function SignIn() {
  const isKeep = getStorageItem('keep') === '1'
  const alert = useAlert()
  const [account, setAccount] = useState('')
  const [password, setPassword] = useState('')
  const [captcha, setCaptcha] = useState('')
  const [captchaImg, setImgurl] = useState('')
  const [keep, setKeep] = useState(isKeep)

  const uvi = getStorageItem(UVI_CACHE_KEY)

  const goConsole = () => {
    window.location.href = '/console/'
  }

  useEffect(() => {
    document.title = `登录-${siteTitle}`
    
    document.querySelector('meta[name="description"]').setAttribute('content', "登录到您的帐户。UniSMS合一短信平台，敏捷、高速、稳定地发送短信至中国大陆及全球用户")

    const token = Cookies.get('uni_jwt')

    if (isKeep && token) {
      const { exp } = parseToken(token) || {}

      if (exp && exp > Date.now() / 1000 - 600) {
        goConsole()
      }
    }

    refreshCaptcha(null)
  }, [])

  // 刷新验证码
  const refreshCaptcha = async (e) => {
    let captchaImg = await createCaptchaImg(uvi)
    setImgurl(captchaImg)
  }

  const submit = (e) => {
    e.preventDefault()

    if (!account) {
      alert.error('请输入登录账号')
      return
    }

    if (!password) {
      alert.error('请输入密码')
      return
    }

    if (!captcha) {
      alert.error('请输入图形验证码')
      return
    }

    request({
      method: 'POST',
      url: '/api/signin',
      data: {
        account,
        password,
        keep,
        uvi,
        captcha,
      }
    })
      .then(res => {
        setStorageItem('keep', keep ? '1' : '0')
        goConsole()
      })
      .catch(e => {
        alert.error(e.message)
      })
  }
  const loginFromUniCloud = () => {
    request({ method: "get", url: "/api/UniCloud/Login", params: { r: Math.random() } })
      .then((t) => {
        const data = t.data;
        const url = new URL("/login", data.host);
        data.host = null;
        Object.keys(data).forEach((c) => {
          const key = c && `${c}`.trim();
          const value = data[c] && `${data[c]}`.trim();
          if (key && value) url.searchParams.append(key, value);
        });
        const currentUrl = new URL(window.location.href);
        url.searchParams.append("redirect", new URL("external/uni_cloud", currentUrl.origin).toString());
        window.location.href = url.toString();
      })
      .catch((e) => {
        alert.error(e.message);
      });
  };

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h1 className="h1">欢迎回到 UniSMS</h1>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                <form>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="account">账号</label>
                      <input id="account" type="text" onChange={e => setAccount(e.target.value)} className="form-input w-full text-gray-800" placeholder="手机号/E-mail" autoComplete="username" required />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <div className="flex justify-between">
                        <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="password">密码</label>
                        <Link to="/reset-password" className="text-sm font-medium text-blue-600 hover:underline">忘记密码？</Link>
                      </div>
                      <input id="password" type="password" onChange={e => setPassword(e.target.value)} className="form-input w-full text-gray-800" placeholder="输入密码" autoComplete="current-password" required />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="captcha">图形验证码</label>
                      <div className="flex">
                        <input id="captcha" onChange={e => setCaptcha(e.target.value)} type="text" className="form-input w-full text-gray-800 mr-2" placeholder="输入右边的图形验证码" autoComplete="off" required />
                        <img id="captcha_img_id" src={captchaImg} onClick={refreshCaptcha} />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <div className="flex justify-between">
                        <label className="flex items-center">
                          <input type="checkbox" checked={keep} onChange={e => setKeep(e.target.checked)} className="form-checkbox" />
                          <span className="text-gray-600 ml-2">保持登录</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mt-6">
                    <div className="w-full px-3">
                      <button className="btn text-white bg-blue-600 hover:bg-blue-700 w-full" onClick={submit}>登 录</button>
                    </div>
                  </div>
                </form>
                {/* <div className="flex items-center my-6">
                  <div className="border-t border-gray-300 flex-grow mr-3" aria-hidden="true"></div>
                  <div className="text-gray-600 italic">Or</div>
                  <div className="border-t border-gray-300 flex-grow ml-3" aria-hidden="true"></div>
                </div>
                <form>
                  <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full px-3">
                      <button className="btn px-0 text-white bg-gray-900 hover:bg-gray-800 w-full relative flex items-center">
                        <svg className="w-4 h-4 fill-current text-white opacity-75 flex-shrink-0 mx-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.95 0C3.578 0 0 3.578 0 7.95c0 3.479 2.286 6.46 5.466 7.553.397.1.497-.199.497-.397v-1.392c-2.187.497-2.683-.993-2.683-.993-.398-.895-.895-1.193-.895-1.193-.696-.497.1-.497.1-.497.795.1 1.192.795 1.192.795.696 1.292 1.888.895 2.286.696.1-.497.298-.895.497-1.093-1.79-.2-3.578-.895-3.578-3.975 0-.895.298-1.59.795-2.087-.1-.2-.397-.994.1-2.087 0 0 .695-.2 2.186.795a6.408 6.408 0 011.987-.299c.696 0 1.392.1 1.988.299 1.49-.994 2.186-.795 2.186-.795.398 1.093.199 1.888.1 2.087.496.596.795 1.291.795 2.087 0 3.08-1.889 3.677-3.677 3.875.298.398.596.895.596 1.59v2.187c0 .198.1.497.596.397C13.714 14.41 16 11.43 16 7.95 15.9 3.578 12.323 0 7.95 0z" />
                        </svg>
                        <span className="flex-auto pl-16 pr-8 -ml-16">Continue with GitHub</span>
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3">
                    <div className="w-full px-3">
                      <button className="btn px-0 text-white bg-red-600 hover:bg-red-700 w-full relative flex items-center">
                        <svg className="w-4 h-4 fill-current text-white opacity-75 flex-shrink-0 mx-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.9 7v2.4H12c-.2 1-1.2 3-4 3-2.4 0-4.3-2-4.3-4.4 0-2.4 2-4.4 4.3-4.4 1.4 0 2.3.6 2.8 1.1l1.9-1.8C11.5 1.7 9.9 1 8 1 4.1 1 1 4.1 1 8s3.1 7 7 7c4 0 6.7-2.8 6.7-6.8 0-.5 0-.8-.1-1.2H7.9z" />
                        </svg>
                        <span className="flex-auto pl-16 pr-8 -ml-16">Continue with Google</span>
                      </button>
                    </div>
                  </div>
                </form> */}
                <div className="text-gray-600 text-center mt-6">
                  还没有账号？<Link to="/signup" className="text-blue-600 hover:underline transition duration-150 ease-in-out">注册</Link>
                </div>

                <div className="text-gray-600 text-center mt-6">
                  <a style={{ display: "inline-block", "vertical-align": "middle", fontSize: 0 }} href="https://www.proginn.com/oauth2/authorize?response_type=code&client_id=UniSMS&redirect_uri=https%3A%2F%2Funisms.apistd.com%2Fproginn%2Fauth" className="text-blue-600 hover:underline transition duration-150 ease-in-out">
                    <img src={require("../images/proginn_logo.png")} style={{ width: "24px", display: "inline-block", fontSize: 0 }} alt="程序员客栈登录" />
                  </a>
                  {/* <button style={{ display: "inline-block", "vertical-align": "middle", fontSize: 0 }} class="uni_cloud_login" onClick={loginFromUniCloud}>
                    <img src={require("../images/unisms.logo.png")} style={{ height: "24px" }} alt="合一云登录" />
                  </button> */}
                </div>
              </div>

            </div>
          </div>
        </section>

      </main>

    </div>
  );
}

export default SignIn;
