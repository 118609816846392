import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useHistory, useLocation } from 'react-router';
import { siteTitle, UVI_CACHE_KEY } from '../constant';
import { request } from '../utils/Request';

import Header from '../partials/Header';
import { getQueryParams, createCaptchaImg } from '../utils/URL';
import { getStorageItem } from '../utils/Storage'

const STAGE_MSG_TEXT = [
  // 0: Default
  '输入您注册账号时使用的手机号码，我们将通过短信向您发送验证码以校验身份。',
  // 1: Sent
  '',
  // 2: Reset
  '设置您的新密码，这次请记住了哟'
]

const STAGE_BUTTON_TEXT = [
  // 0: Default
  '发送验证短信',
  // 1: Sent
  '提交校验',
  // 2: Reset
  '完成重置'
]

function ResetPassword() {
  const alert = useAlert()
  const history = useHistory()
  const location = useLocation()
  const [stage, setStage] = useState(0)
  const [phone, setPhone] = useState('')
  const [captcha, setCaptcha] = useState('')
  const [captchaImg, setImgurl] = useState('')
  const [code, setCode] = useState('')
  const [token, setToken] = useState('')
  const [password, setPassword] = useState('')
  const [repassword, setRepassword] = useState('')

  const uvi = getStorageItem(UVI_CACHE_KEY)

  useEffect(() => {
    document.title = `重设密码 - ${siteTitle}`
  }, [])

  useEffect(() => {
    let _stage = Number(getQueryParams(location.search, 'stage')) || 0

    if (_stage > 0 && !phone) {
      _stage = 0
    }
    setStage(_stage)

    refreshCaptcha(null)
  }, [location.search])

  // 刷新验证码
  const refreshCaptcha = async (e) => {
    let captchaImg = await createCaptchaImg(uvi)
    setImgurl(captchaImg)
  }

  const submit = async (e) => {
    e.preventDefault()

    let nextStage = stage + 1

    // send code
    if (nextStage === 1) {
      if (!phone) {
        alert.error('请输入手机号')
        return
      }

      if (!captcha) {
        alert.error('请输入图形验证码')
        return
      }
    
      try {
        await request({
          method: 'POST',
          url: '/api/verif/code',
          data: {
            to: phone,
            scene: 'reset_pass',
            uvi,
            captcha,
          }
        })
      } catch (e) {
        alert.error(e.message)
        return
      }

      alert.success('短信已发送，请注意查收')
    }
    // verify code
    else if (nextStage === 2) {
      if (!code) {
        alert.error('请输入验证码')
        return
      }

      try {
        const ret = await request({
          method: 'POST',
          url: '/api/verif/verification',
          data: {
            phone,
            code,
            scene: 'reset_pass',
          }
        })
        setToken(ret.data.token)
      } catch (e) {
        alert.error(e.message)
        return
      }

      alert.success('身份校验成功')
    }
    // reset
    else if (nextStage === 3) {
      if (!password) {
        alert.error('请输入新密码')
        return
      }

      if (!repassword) {
        alert.error('请确认新密码')
        return
      }

      if (password !== repassword) {
        alert.error('两次密码输入不一致')
        return
      }

      try {
        await request({
          method: 'POST',
          url: '/api/account/reset/password',
          data: {
            token,
            password,
          }
        })
      } catch (e) {
        alert.error(e.message)
        return
      }

      alert.success('重置密码成功，请登录')
      history.push(`/signin`)
      return
    }
    else {
      nextStage = 0
    }

    setStage(nextStage)
    history.push(`/reset-password?stage=${nextStage}`)
  }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h1 className="h1 mb-4">{stage === 2 ? '欢迎成功': '让您重新'}回到 UniSMS</h1>
                <p className="text-gray-600 text-lg md:text-xl">{STAGE_MSG_TEXT[stage] || STAGE_MSG_TEXT[0]}</p>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                <form>
                  <div className={`flex flex-wrap -mx-3 mb-4 ${stage === 2 ? 'hidden' : ''}`}>
                    <div className="w-full px-3">
                      <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="account">手机号 <span className="text-red-600">*</span></label>
                      <input id="account" type="text" onChange={e => setPhone(e.target.value)} autoComplete="tel" className={`form-input w-full text-gray-800 ${stage > 0 ? 'disabled:bg-gray-200' : ''}`} disabled={stage > 0} placeholder="输入您的注册手机号" required />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="captcha">图形验证码 <span className="text-red-600">*</span></label>
                      <div className="flex">
                        <input id="captcha" onChange={e => setCaptcha(e.target.value)} type="text" className="form-input w-full text-gray-800 mr-2" placeholder="输入右边的图形验证码" autoComplete="off" required />
                        <img id="captcha_img_id" src={captchaImg} onClick={refreshCaptcha} />
                      </div>
                    </div>
                  </div>
                  {/* code */}
                  { stage === 1 ?
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="code">验证码 <span className="text-red-600">*</span></label>
                      <input id="code" type="text" onChange={e => setCode(e.target.value)} autoComplete="off" className="form-input w-full text-gray-800" placeholder="输入验证码" required />
                    </div>
                  </div> : '' }
                  <div className={`flex flex-wrap -mx-3 mb-4 ${stage === 2 ? '' : 'hidden'}`}>
                    <div className="w-full px-3">
                      <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="password">新密码 <span className="text-red-600">*</span></label>
                      <input id="password" type="password" onChange={e => setPassword(e.target.value)} autoComplete="new-password" className="form-input w-full text-gray-800" placeholder="输入新密码" required />
                    </div>
                  </div>
                  <div className={`flex flex-wrap -mx-3 mb-4 ${stage === 2 ? '' : 'hidden'}`}>
                    <div className="w-full px-3">
                      <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="repassword">重复密码 <span className="text-red-600">*</span></label>
                      <input id="repassword" type="password" onChange={e => setRepassword(e.target.value)} autoComplete="new-password" className="form-input w-full text-gray-800" placeholder="再次输入新密码" required />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mt-6">
                    <div className="w-full px-3">
                      <button className="btn text-white bg-blue-600 hover:bg-blue-700 w-full" onClick={submit}>{STAGE_BUTTON_TEXT[stage] || STAGE_BUTTON_TEXT[2]}</button>
                    </div>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </section>

      </main>

    </div>
  );
}

export default ResetPassword;
