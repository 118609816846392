import css from "./style.css";
import React, { useEffect, useState } from "react";
import { request } from "../../utils/Request";

function SignFromUniCloud() {
  const [message, setMessage] = useState("");

  useEffect(() => {
    login();
  }, []);

  // 登录
  const login = async () => {
    const current = new URL(window.location.href);
    const code = current.searchParams.get("code");
    if (code) {
      request({ method: "GET", url: "/api/UniCloud/ExchangeToken", params: { code, r: Math.random() } })
        .then((t) => {
          console.log(`用户[${t.data.id}登录]`);
          window.location.href = "/console/";
        })
        .catch((e) => {
          console.error(e);
          window.location.href = "/signin";
        });
    } else window.location.href = "/signin";
  };
  return (
    <div className="loading">
      <div>
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
      <p>正在登录...</p>
    </div>
  );
}

export default SignFromUniCloud;
