import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import Footer from '../partials/Footer';
import Header from '../partials/Header';
import {siteTitle} from '../constant';
import {getStorageItem, setStorageItem} from '../utils/Storage';

const nfyList = [{
    id: 'nfy.tier0',
    title: '零门槛',
    desc: '初创企业的最佳选择，无门槛零负担',
    price: '0.040',
}, {
    id: 'nfy.tier30',
    title: '明日新星',
    desc: '日常使用常规配置，明日新星之选',
    price: '0.037',
}, {
    id: 'nfy.tier100',
    title: '独角兽',
    desc: '快速增长型企业首选，冲刺独角兽',
    price: '0.035',
}]

const mktList = [{
    id: 'mkt.tier0',
    title: '零门槛',
    desc: '少量营销短信发送，精明之选',
    price: '0.045',
}, {
    id: 'mkt.tier30',
    title: '明日新星',
    desc: '营销推广常规配置，亲民放送',
    price: '0.043',
}, {
    id: 'mkt.tier100',
    title: '独角兽',
    desc: '全用户持续触达，冲刺独角兽',
    price: '0.040',
}]

function PricingStandard() {
    useEffect(() => {
        document.title = `标准国内短信服务定价-短信验证码_短信通知_营销短信-${siteTitle}`

        const meta_keywords = "国内短信,短信验证码,通知短信,营销短信,系统短信"
        const meta_description = "UniSMS国内短信，提供短信验证码、通知短信、营销短信等国内短信服务。覆盖三大运营商网络，3s可达，https加密更安全"

        document.querySelector('meta[name="keywords"]').setAttribute('content', meta_keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', meta_description)
    }, [])

    const history = useHistory()

    const go = (source: string) => {
        const storedSource = getStorageItem('source')
        let mergedSource = `sms.${source}`

        if (storedSource && !/^sms\./.test(storedSource)) {
            mergedSource = `${storedSource},${mergedSource}`
        }

        setStorageItem('source', mergedSource)
        history.push(`/signup?source=${mergedSource}`)
    }

    const genPricingItems = (list: any[]) => {
        const items = []

        for (const item of list) {
            // const featureEls = []

            // for (const feat of item.features) {
            //   featureEls.push(
            //     <p className="my-2"><span className="fa fa-check-circle mr-2 ml-1"></span> {feat}</p>
            //   )
            // }

            items.push(
                <div key={item.id}
                     className="w-full md:w-1/3 bg-white rounded-lg shadow hover:shadow-xl transition duration-100 ease-in-out p-6 md:mr-6 mb-10 md:mb-0">
                    <h3 className="text-gray-600 text-lg">{item.title}</h3>
                    <p className="text-gray-600 mt-1"><span
                        className="font-bold text-black text-3xl">￥{item.price}</span> /条起</p>
                    <p className="text-sm text-gray-600 mt-2">{item.desc}</p>
                    {/* <div className="text-sm text-gray-600 mt-4">
            {featureEls}
          </div> */}
                    <button
                        className="w-full text-blue-700 border border-blue-700 rounded hover:bg-blue-600 hover:border-blue-600 hover:text-white hover:shadow-xl transition duration-150 ease-in-out py-2 mt-4"
                        onClick={() => {
                            go(item.id);
                        }}>选 择
                    </button>
                </div>
            )
        }

        return items
    }

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header/>

            {/*  Page content */}
            <main className="flex-grow">
                {/* <div className="o_ i_ lt aw">
          <h1 className="h1 sm aos-init aos-animate" data-aos="zoom-y-out"></h1>
          <p className="rz la aos-init aos-animate" data-aos="zoom-y-out" data-aos-delay="150">No matter how many team members you have - our pricing is simple, transparent and adapts to the size of your company.</p>
        </div> */}

                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20 pt-48 illustration-section">
                    <div className="relative">
                        <h1 className="h1 mb-4 mx-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-teal-400">UniSMS
                            国内标准短信</h1>
                        <p className="text-gray-600 text-lg md:text-xl mx-4">精选顶级短信上游服务商集中竞价，以最低的价格获得最优质的服务。<br/>任意开通切换，用多少充多少。
                        </p>
                    </div>
                </div>

                {/* 行业 */}
                <section className="relative max-w-6xl mx-auto px-4 sm:px-6 my-24">
                    <div className="text-center my-12">
                        <h2 className="font-bold text-3xl mb-2">国内验证码 / 通知短信</h2>
                        <h4 className="text-gray-600">适用于验证码及事务通知等场景所发送的短信，亦称为行业短信</h4>
                    </div>

                    <div className="flex flex-col md:flex-row px-2 md:px-0">
                        {genPricingItems(nfyList)}
                    </div>
                </section>

                {/* 营销 */}
                <section className="relative max-w-6xl mx-auto px-4 sm:px-6 my-24">
                    <div className="text-center my-12">
                        <h2 className="font-bold text-3xl mb-2">国内营销短信</h2>
                        <h4 className="text-gray-600">适用于营销、推广、运营等场景所发送的短信</h4>
                    </div>

                    <div className="flex flex-col md:flex-row px-2 md:px-0 md:pb-8">
                        {genPricingItems(mktList)}
                    </div>
                </section>

            </main>

            <Footer/>
        </div>
    );
}

export default PricingStandard;
