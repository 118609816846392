import React from 'react'

import style from './index.scss'

export default class Attendant extends React.Component {
  render() {
    return (
      <div class="fixbottomright_ai">
        <div class="show_btn_new">
          <img src={require("../../images/attendant/icon.png")} alt="客户服务"   />
          <div class="scrollsidebar_ai">
            <div class="side_content_ai">
              <div class="bg-primary"></div>
              <div class="content_ai">
                <div class="top">
                  <img src={require("../../images/unisms.logo.png")} alt="合一云" class="logo" />
                  <p class="title_ai">您好 👋</p>
                  <p class="title_ai">我们能提供什么帮助？</p></div>
                <a href="https://www.qinglite.cn/app/ai/chat/QAI2193643543f2eeaae" target="_blank" class="btn-large">
                  <p class="title_ai">向我们发送消息</p>
                  <p class="desc">常见问题、使用帮助、人工咨询等</p>
                  <img src={require("../../images/attendant/right.png")} alt="" class="icon" />
                </a>
                <div class="btns">
                  <a href="https://www.qinglite.cn/search?keywords=合一云" target="_blank">智能搜索</a>
                  <a class="phone">手机访问
                    <div class="qrcode">
                      <img src={require("../../images/attendant/qr.png")} alt="AI客服二维码" />
                      <p>使用微信扫一扫</p>
                    </div>
                  </a>
                </div></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}