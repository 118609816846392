export const getStorageItem = (key) => {
    try {
        return window.localStorage.getItem(key)
    } catch (e) {
        return null
    }
}

export const setStorageItem = (key, value) => {
    try {
        return window.localStorage.setItem(key, value)
    } catch (e) {
        return null
    }
}
