import React from 'react'
import MetaTags from 'react-meta-tags';
import Header from '../partials/Header';
import HeroHome from '../partials/HeroHome';
import FeaturesHome from '../partials/Features';
import FeaturesBlocks from '../partials/FeaturesBlocks';
import Testimonials from '../partials/Testimonials';
import ManagedChannels from '../partials/ManagedChannels';
import Newsletter from '../partials/Newsletter';
import Footer from '../partials/Footer';

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <MetaTags>
        <title>UniSMS 合一短信-高可用聚合API短信平台-国际短信验证码</title>
        <meta name="keywords" content="国际短信,短信平台,短信验证码,短信接口,短信接码,UniSMS" />
        <meta name="description" content="UniSMS合一短信平台，国际短信API。敏捷高速稳定地发送短信验证码至全球用户。提供开放一体化的跨短信通道集成管理系统" />
      </MetaTags>

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page sections */}
        <HeroHome />
        {/* <FeaturesHome /> */}
        <FeaturesBlocks />
        <Testimonials />
        <ManagedChannels />
        <Newsletter />

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Home;
