import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Link, useLocation } from 'react-router-dom';
import { siteTitle, UVI_CACHE_KEY } from '../constant';
import Header from '../partials/Header';
import { request } from '../utils/Request';
import { getStorageItem, setStorageItem } from '../utils/Storage';
import { getQueryParams } from '../utils/URL';

function ProginnAuth() {

  const goConsole = () => {
    window.location.href = '/console/'
  }

  useEffect(() => {
    document.title = `程序员客栈授权登录 - ${siteTitle}`

    const code = getQueryParams(location.search, 'code')

    // 这一行不能少！既可以避免前端报错，又可以解决打包出现的 [404] NOT FOUND 问题
    if (!code) {
      return
    }

    request({
      method: 'POST',
      url: '/api/proginnauth',
      data: {
        code
      }
    })
    .then(res => {
      const userInfo = res.data.user_info
      if (!userInfo || !userInfo.uid) {
        alert.error('请重新登录')
        return
      }

      // console.log(userInfo)
      setProginn(userInfo.nickname + ' (UID: ' + userInfo.uid + ')')
      setPhone(userInfo.login_mobile)
      setProginnUid(userInfo.uid)

      // 登录
      if (res.data.auth_type === 1) {
        setStorageItem('keep', '1')
        goConsole()
      }
    })
    .catch(e => {
      alert.error(e.message)
    })
  }, [])

  const alert = useAlert()
  const location = useLocation()
  const [proginn, setProginn] = useState('')
  const [phone, setPhone] = useState('')
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const [remainSecs, setRemainSecs] = useState(60)
  const [resendWaiting, setResendWaiting] = useState(false)
  const [proginnUid, setProginnUid] = useState('')

  let timer
  const uvi = getStorageItem(UVI_CACHE_KEY)

  const countdown = () => {
    setRemainSecs(secs => {
      if (secs > 1) {
        return secs - 1
      } else {
        timer && clearInterval(timer)
        setResendWaiting(false)
        return 60
      }
    })
  }

  const sendCode = (e) => {
    e.preventDefault()

    if (!phone) {
      alert.error('请输入手机号')
      return
    }

    request({
      method: 'POST',
      url: '/api/verif/code',
      data: {
        scene: 'pub_verif_bindmob',
        to: phone,
        uvi,
      }
    })
      .then(res => {
        alert.success('短信已发送，请注意查收')
        setResendWaiting(true)
        timer = setInterval(countdown, 1000)
      })
      .catch(e => {
        alert.error(e.message)
      })
  }

  const submit = (e) => {
    e.preventDefault()

    const source = getStorageItem('source') || getQueryParams(location.search, 'source') || getQueryParams(location.search, 's')

    if (!phone) {
      alert.error('请输入手机号')
      return
    }

    if (!code) {
      alert.error('请输入短信验证码')
      return
    }

    if (!password) {
      alert.error('请输入密码')
      return
    } else if (password.length < 6) {
      alert.error('密码长度须在6个字符以上')
      return
    }

    request({
      method: 'POST',
      url: '/api/proginnsignup',
      data: {
        phone,
        code,
        password,
        source: source,
        uid: proginnUid,
      }
    })
      .then(res => {
        window.location.href = '/console/'
      })
      .catch(e => {
        alert.error(e.message)
      })
  }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div style={{ height: '100%' }} className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h1 className="h1">程序员客栈授权登录</h1>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                <form>
                  {/* <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="name">Name <span className="text-red-600">*</span></label>
                      <input id="name" type="text" className="form-input w-full text-gray-800" placeholder="Enter your name" required />
                    </div>
                  </div> */}
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-800 text-sm font-medium mb-1">程序员客栈账号</label>
                      <input type="text" id="proginn" value={ proginn } disabled="disabled" className="form-input w-full text-gray-800 form-input-disabled" />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="phone">手机号 <span className="text-red-600">*</span></label>
                      <input id="phone" value={ phone } onChange={e => setPhone(e.target.value)} type="text" className="form-input w-full text-gray-800" placeholder="输入手机号" autoComplete="tel" required />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="code">短信验证码 <span className="text-red-600">*</span></label>
                      <div className="flex">
                        <input id="code" onChange={e => setCode(e.target.value)} type="text" className="form-input w-full text-gray-800 mr-2" placeholder="输入短信验证码" autoComplete="off" required />
                        <button onClick={sendCode} disabled={resendWaiting} className="btn text-blue-600 bg-blue-100 shadow-none" style={{whiteSpace: 'nowrap'}}>{resendWaiting ? `重新发送 (${remainSecs})` : '发送验证码'}</button>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="password">密码 <span className="text-red-600">*</span></label>
                      <input id="password" onChange={e => setPassword(e.target.value)} type="password" className="form-input w-full text-gray-800" placeholder="输入密码" autoComplete="new-password" required />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mt-6">
                    <div className="w-full px-3">
                      <button className="btn text-white bg-blue-600 hover:bg-blue-700 w-full" onClick={submit}>绑定手机号并登录</button>
                    </div>
                  </div>
                  {/* <div className="text-sm text-gray-500 text-center mt-3">
                    创建帐户即表示您同意我们的<a className="underline" href="#0">使用条款</a>以及<a className="underline" href="#0">隐私政策</a>。
                  </div> */}
                </form>
                {/* <div className="flex items-center my-6">
                  <div className="border-t border-gray-300 flex-grow mr-3" aria-hidden="true"></div>
                  <div className="text-gray-600 italic">Or</div>
                  <div className="border-t border-gray-300 flex-grow ml-3" aria-hidden="true"></div>
                </div>
                <form>
                  <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full px-3">
                      <button className="btn px-0 text-white bg-gray-900 hover:bg-gray-800 w-full relative flex items-center">
                        <svg className="w-4 h-4 fill-current text-white opacity-75 flex-shrink-0 mx-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.95 0C3.578 0 0 3.578 0 7.95c0 3.479 2.286 6.46 5.466 7.553.397.1.497-.199.497-.397v-1.392c-2.187.497-2.683-.993-2.683-.993-.398-.895-.895-1.193-.895-1.193-.696-.497.1-.497.1-.497.795.1 1.192.795 1.192.795.696 1.292 1.888.895 2.286.696.1-.497.298-.895.497-1.093-1.79-.2-3.578-.895-3.578-3.975 0-.895.298-1.59.795-2.087-.1-.2-.397-.994.1-2.087 0 0 .695-.2 2.186.795a6.408 6.408 0 011.987-.299c.696 0 1.392.1 1.988.299 1.49-.994 2.186-.795 2.186-.795.398 1.093.199 1.888.1 2.087.496.596.795 1.291.795 2.087 0 3.08-1.889 3.677-3.677 3.875.298.398.596.895.596 1.59v2.187c0 .198.1.497.596.397C13.714 14.41 16 11.43 16 7.95 15.9 3.578 12.323 0 7.95 0z" />
                        </svg>
                        <span className="flex-auto pl-16 pr-8 -ml-16">Continue with GitHub</span>
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3">
                    <div className="w-full px-3">
                      <button className="btn px-0 text-white bg-red-600 hover:bg-red-700 w-full relative flex items-center">
                        <svg className="w-4 h-4 fill-current text-white opacity-75 flex-shrink-0 mx-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.9 7v2.4H12c-.2 1-1.2 3-4 3-2.4 0-4.3-2-4.3-4.4 0-2.4 2-4.4 4.3-4.4 1.4 0 2.3.6 2.8 1.1l1.9-1.8C11.5 1.7 9.9 1 8 1 4.1 1 1 4.1 1 8s3.1 7 7 7c4 0 6.7-2.8 6.7-6.8 0-.5 0-.8-.1-1.2H7.9z" />
                        </svg>
                        <span className="flex-auto pl-16 pr-8 -ml-16">Continue with Google</span>
                      </button>
                    </div>
                  </div>
                </form> */}
                <div className="text-gray-600 text-center mt-6">
                  <Link to="/signin" className="text-blue-600 hover:underline transition duration-150 ease-in-out">返回重新登录</Link>
                </div>
              </div>

            </div>
          </div>
        </section>

      </main>

    </div>
  );
}

export default ProginnAuth;
