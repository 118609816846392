import React from 'react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import Footer from '../partials/Footer';
import Header from '../partials/Header';
// docs
import aboutDoc from '../docs/privacy.md';

function Privacy() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />
      {/*  Page content */}
      <main className="flex-grow">
        <div class="max-w-6xl mx-auto px-4 pt-24">
          <div className="w-full flex">
            <div className="min-w-0 flex-auto px-2 pt-0 pb-24">
              <ReactMarkdown children={aboutDoc} remarkPlugins={[gfm]} className="markdown" />
            </div>
          </div>
        </div>
      </main>
      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Privacy;
